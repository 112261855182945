import React from 'react';
import styles from './../../css/OfficeAssistant.module.css';

function Screenshot({title, description, imgSrc, isReversed}) {
    const contentOrder = isReversed ? 'order-2' : 'order-1';
    const imageOrder = isReversed ? 'order-1' : 'order-2';

    return (
        <div className={`${styles.screenshotWrapper} ${isReversed ? styles.reversed : ''}`}>
            <img src={imgSrc} alt={title} className={styles.screenshotImg}/>
            <div className={`${styles.screenshotText} ${contentOrder}`}>
                <h3 className={styles.screenshotTitle}>{title}</h3>
                <p className={styles.screenshotDescription}>{description}</p>
            </div>

        </div>
    );
}

export default Screenshot;