import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Typography, Box, CardActions } from "@mui/material";
import styles from './../../css/Articles.module.css';  // Import the CSS module

const KnowledgeBaseCard = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(
                    "https://inp-b-prod.itcs.hr/knowledge_base_app/company-public-posts/1"
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setPosts(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    const handleExpandClick = (id) => {
        setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const truncateText = (text, limit) => {
        return text.length > limit ? `${text.slice(0, limit)}...` : text;
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className={styles.container}>
            {posts.map((post) => (
                <div className={styles.card} key={post.id}>
                    <Card sx={{height: "100%", display: "flex", flexDirection: "column"}}>
                        <CardContent className={styles.cardContent}>
                            <Typography gutterBottom sx={{color: "text.secondary", fontSize: 14}}>
                                {`${post.date}`}
                            </Typography>
                            <Typography variant="h5" component="div">
                                {post.title}
                            </Typography>
                            <Typography sx={{color: "text.secondary", mb: 1.5}}>
                                <b>Autor:</b> {post.posted_by_full_name}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 1,
                                    marginTop: 1,
                                }}
                            >
                                {post.tags.split(",").map((tag, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            backgroundColor: "#F8D00D",
                                            color: "black",
                                            padding: "4px 8px",
                                            borderRadius: "12px",
                                            fontSize: "0.875rem",
                                            fontWeight: "bold",
                                            display: "inline-block",
                                        }}
                                    >
                                        {tag.trim()}
                                    </Box>
                                ))}
                            </Box>
                            <Typography
                                variant="body2"
                                sx={{marginTop: 1, color: "text.secondary"}}
                            >
                                <strong>Kategorija:</strong> {post.category_name}
                            </Typography>
                        </CardContent>

                        <CardActions className={styles.cardActions}>
                            <Button size="small" href={`/post/${post.id}`}>
                                Pročitaj više
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            ))}
        </div>


    );
};

export default KnowledgeBaseCard;
