import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './../../css/OfficeAssistant.module.css';
import FeatureIcon from './FeatureIcon';
import Screenshot from './Screenshot';
import heroImage from './../../img/OA hero.png';
import noticeBoard from './../../img/Oglasna_Ploca.jpg';
import documents from './../../img/Aktualni_Dokumenti.jpg';
import table from './../../img/Moj_Stol.jpg';
import vacation from './../../img/Godisnji.jpg';
import projects from './../../img/Projekti.jpg';
import base from './../../img/Baza_Znanja.jpg';
import vehicles from './../../img/Sluzbena_Vozila.jpg';
import workHours from './../../img/Radno_Vrijeme.jpg';
import hr from './../../img/Kadrovska.jpg';
import trip from './../../img/Putni_Nalozi.jpg';
import meeting from './../../img/E_Sastanci.jpg';
import chat from './../../img/Chat.jpg';
import date from './../../img/Terminator.jpg';
import gallery from './../../img/Galerija.jpg';
import notification from './../../img/Podsjetnici.jpg';
import timeIcon from './../../img/Time icon.png';
import dashboardIcon from './../../img/Dashboard icon.png';
import performanceIcon from './../../img/Performance icon.png';
import dashboard from './../../img/Kontrolna_Ploca.jpg'
import constructis from './../../img/Constructis.jpg'
import eLearning from './../../img/E_Learning.jpg'
import events from './../../img/gradient_21.17.10-min.jpg'
import {Navigate} from "react-router-dom";
import Navigation from "../Navigation";
import yellowLine from "./../../img/yellow line.png";
import Footer from "../Footer";

const OfficeAssistant = () => {
    const {t} = useTranslation();

    const features = [
        {
            imgSrc: timeIcon,
            title: t('realTime'),
            description: t('timeDesc')
        },
        {
            imgSrc: dashboardIcon,
            title: t('dashboard'),
            description: t('dashboardDesc')
        },
        {
            imgSrc: performanceIcon,
            title: t('performance'),
            description: t('performanceDesc')
        }
    ];

    const screenshots = [
        {
            title: t('noticeBoard'),
            description: t('NoticeBoardDesc'),
            imgSrc: noticeBoard
        },
        {
            title: t('documents'),
            description: t('documentsDesc'),
            imgSrc: documents
        },
        {
            title: t('table'),
            description: t('tableDesc'),
            imgSrc: table
        },
        {
            title: t('vacation'),
            description: t('vacationDesc'),
            imgSrc: vacation
        },
        {
            title: t('projects'),
            description: t('projectsDesc'),
            imgSrc: projects
        },
        {
            title: t('knowledge'),
            description: t('knowledgeDesc'),
            imgSrc: base
        },
        {
            title: t('vehicles'),
            description: t('vehiclesDesc'),
            imgSrc: vehicles
        },
        {
            title: t('workHours'),
            description: t('workHoursDesc'),
            imgSrc: workHours
        },
        {
            title: t('hr'),
            description: t('hrDesc'),
            imgSrc: hr
        },
        {
            title: t('travel'),
            description: t('travelDesc'),
            imgSrc: trip
        },
        {
            title: t('meetings'),
            description: t('meetingsDesc'),
            imgSrc: meeting
        },
        {
            title: t('chat'),
            description: t('chatDesc'),
            imgSrc: chat
        },
        {
            title: t('terminator'),
            description: t('terminatorDesc'),
            imgSrc: date
        },
        {
            title: t('gallery'),
            description: t('galleryDesc'),
            imgSrc: gallery
        },
        {
            title: t('reminders'),
            description: t('remindersDesc'),
            imgSrc: notification
        },
        {
            title: t('events'),
            description: t('eventsDesc'),
            imgSrc: events
        },
        {
            title: t('dash'),
            description: t('dashDesc'),
            imgSrc: dashboard
        },
        {
            title: t('constructis'),
            description: t('constructisDesc'),
            imgSrc: constructis
        }, {
            title: t('learning'),
            description: t('learningDesc'),
            imgSrc: eLearning
        },
    ];

    return (
        <div className={styles.container}>
            <Navigation/>
            <header className={styles.hero}>
                <img src={heroImage} alt="Hero"/>
                <div className={styles.oaText}>
                    <h1>{t('oa')}</h1>
                    <p>{t('oaDesc')}</p>
                    <a href="/contact" style={{textDecoration: "none", color: "black"}}>
                        <button>{t('demo')}</button>
                    </a>
                </div>
            </header>

            <section className={styles.features}>
                <h2>{t('featuresTitle')}</h2>
                <p>{t('featuresSubtitle')}</p>
                <div className={styles.featureList}>
                    <div className={styles.list}>
                        {features.map((feature, index) => (
                            <FeatureIcon
                                key={index}
                                imgSrc={feature.imgSrc}
                                title={feature.title}
                                description={feature.description}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <section className={styles.screenshots}>
                <div className={styles.screenshotList}>
                    {screenshots.map((screenshot, index) => (
                        <Screenshot
                            key={index}
                            title={screenshot.title}
                            description={screenshot.description}
                            imgSrc={screenshot.imgSrc}
                            isReversed={true}
                        />
                    ))}
                </div>
            </section>

            <Footer/>
        </div>
    );
};

export default OfficeAssistant;
