import React, { useState } from 'react';
import Header from './Navigation';
import HeroSection from './HeroSection';
import ServicesSection from './ServicesSection';
import AboutSection from './AboutSection';
import FeaturesSection from './FeatureSection';
import ProductSection from './ProductsSection';
import ContactForm from './ContactForm';
import Footer from './Footer';
import ContactSection from "./ContactSection";
import styles from './../css/HomePage.module.css';
import Navigation from "./Navigation";

const HomePage = () => {
    const [isContactPopupVisible, setContactPopupVisible] = useState(false);

    const toggleContactPopup = () => {
        setContactPopupVisible(!isContactPopupVisible);
    };

    return (
        <div className={styles.homePage}>
            <Navigation />
            <main>
                <HeroSection />
                <ServicesSection />
                <AboutSection />
                <FeaturesSection />
                <ProductSection />
            </main>
            <Footer />

            {/* Floating button */}
            <div className={styles.floatingButton} onClick={toggleContactPopup}>
                <span className={styles.icon}>📞</span>
            </div>

            {/* Overlay and contact popup */}
            {isContactPopupVisible && (
                <>
                    <div className={styles.overlay} onClick={toggleContactPopup}></div>
                    <div className={styles.contactPopup}>
                        <h3>Kontaktirajte nas</h3>
                        <p><strong>Email:</strong> <a href="mailto:info@itcs.hr">info@itcs.hr</a></p>
                        <p><strong>Telefon:</strong> <a href="tel:+385911280108">+385 91 128 0108</a></p>
                        <p><strong>Adresa:</strong> Mekušanska cesta 5, 47 000 Karlovac, Hrvatska</p>
                        <button onClick={toggleContactPopup} className={styles.closeButton}>Zatvori</button>
                    </div>
                </>
            )}
        </div>
    );
};

export default HomePage;
