import React from 'react';
import styles from './../../css/OfficeAssistant.module.css';

function FeatureIcon({ imgSrc, title, description }) {
  return (
    <div className={styles.featureIcon}>
      <img src={imgSrc} alt={title} className={styles.iconImage} />
      <h3 className={styles.iconTitle}>{title}</h3>
      <p className={styles.iconDescription}>{description}</p>
    </div>
  );
}

export default FeatureIcon;