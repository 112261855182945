import React from 'react';
import styles from './../../css/ProductsPage.module.css';

export const FeatureCard = ({ icon, title, description }) => {
  return (
    <article className={styles.featureCard}>
      <img src={icon} alt="" className={styles.featureIcon} />
      <div className={styles.featureContent}>
        <h3 className={styles.featureCardTitle}>{title}</h3>
        <p className={styles.featureCardText}>{description}</p>
        <a href="/oa" className={styles.learnMoreLink}>Saznaj više</a>
      </div>
    </article>
  );
};