import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../css/HeroSection.module.css';
import HeroImage from "./../img/Hero image.png";

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContent}>
        <h1 className={styles.heroTitle}>
          {t('mainTitle').split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </h1>
        <p className={styles.heroDescription}>
          {t('subtitle')}
        </p>
          <a href="/contact">
              <button className={styles.ctaButton}>{t('learnMore')}</button>
          </a>
      </div>
        <img
            loading="lazy"
            src={HeroImage}
        alt={t('heroImageAlt', { defaultValue: 'Digital transformation illustration' })}
        className={styles.heroImage}
      />
    </section>
  );
};

export default HeroSection;
