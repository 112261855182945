import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../../css/ProductsPage.module.css';
import audioImage from './../../img/AudioImage.png';

export const AudioSection = () => {
  const { t } = useTranslation(); // Access the translation function

  return (
    <section className={styles.audioSection}>
      <div className={styles.audioContent}>
        <div className={styles.audioInfo}>
          <h2 className={styles.audioTitle}>{t('audioTitle')}</h2>
          <p className={styles.audioDescription}>
            {t('audioLeft')}
          </p>
          <a href="/contact" style={{textDecoration:"none", color:"black"}}>
            <button className={styles.audioButton}>
              {t('contact')}
            </button>
          </a>

        </div>
        <div className={styles.audioDemo}>
          <img src={audioImage} alt="" className={styles.audioDemoImage} />
          <p className={styles.audioDemoText}>
            {t('audioRight')}
          </p>
        </div>
      </div>
    </section>
  );
};
