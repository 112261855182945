import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../../css/ProductsPage.module.css';
import associationImage from "./../../img/AssociationsImage.png";

export const AssociationSection = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.associationSection}>
      <div className={styles.associationContent}>
        <img src={associationImage} alt="" className={styles.associationImage} />
        <div className={styles.associationText}>
          <h2 className={styles.associationTitle}>{t("associationsTitle")}</h2>
          <p className={styles.associationDescription}>
            {t("associatioonsDesc")}
          </p>
          <a href="https://www.udruga.online" target={"_blank"} className={styles.associationLink}>
           https://www.udruga.online
          </a>
        </div>
      </div>
    </section>
  );
};
