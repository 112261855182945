import React from 'react';
import styles from './../css/ServiceCard.module.css';

const ServiceCard = ({ IconComponent, title, description }) => (
  <div className={styles.serviceCard}>
    {/* Renderujemo MUI ikonu umesto slike */}
    <div className={styles.serviceIcon}>
      <IconComponent style={{ fontSize: 32 }} />
    </div>
    <div className={styles.cardContent}>
      <h3 className={styles.serviceTitle}>{title}</h3>
      <p className={styles.serviceDescription}>{description}</p>
    </div>
  </div>
);

export default ServiceCard;
