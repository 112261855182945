import React from 'react';
import styles from './../css/FeatureCard.module.css';
import CircleIcon from '@mui/icons-material/Circle';

const FeatureCard = ({ title, description }) => (
  <div className={styles.featureCard}>
    <div className={styles.featureIcon} />
      <div className={styles.featuresCardText}>
          <h3 className={styles.featureTitle}>{title} <br/> {description}</h3>

      </div>
  </div>
);

export default FeatureCard;