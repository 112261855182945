import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./../../css/Articles.module.css"
import Navigation from "../Navigation";
import Footer from "../Footer";

const SinglePost = () => {
    const { id } = useParams(); // Preuzimanje ID-a iz URL-a
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await fetch(
                    `https://inp-b-prod.itcs.hr/knowledge_base_app/company-public-posts/get/${id}/`
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setPost(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [id]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <div>
                <Navigation/>
                <div className={styles.postImage}>
                    {/*{post.files.length > 0 && (
                    <img
                        src={post.files[0].url}
                        alt={post.files[0].file_name}
                        style={{maxWidth: "100%", height: "auto"}}
                    />
                )}*/}
                    <h1>{post.title}</h1>
                    <p>
                        <strong>Autor:</strong> {post.posted_by_full_name} on {post.date}
                    </p>
                    <p>
                        <strong>Kategorija:</strong> {post.category_name}
                    </p>


                    <div dangerouslySetInnerHTML={{__html: post.body}}></div>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default SinglePost;
