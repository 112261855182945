import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../css/ProductSection.module.css';
import OAImage from "./../img/OA image.png";

const ProductSection = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.productSection}>
      <div className={styles.productContent}>
        <h2 className={styles.productTitle}>{t('productTitle')}</h2>
        <h3 className={styles.productSubtitle}>{t('oa')}</h3>
        <p className={styles.productDescription}>
          {t('productDesc1')}
        </p>
          <a href="/oa" style={{textDecoration:"none", color:"black"}}>
              <button className={styles.ctaButton}>{t('See more')}</button>
          </a>
      </div>
        <div className={styles.productImageContainer}>
        <img loading="lazy" src={OAImage} alt={t('oa')} className={styles.productImage} />
      </div>
    </section>
  );
};

export default ProductSection;
