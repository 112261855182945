import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../../css/ProductsPage.module.css';
import { FeatureCard } from './FeatureCard';
import Footer from './../Footer';
import { AssociationSection } from "./AssociationsSection";
import { AudioSection } from "./AudioSection";
import productsHero from "./../../img/Products Hero.png";
import vehicles from "./../../img/Projekti-proizvodi.png";
import vacation from "./../../img/Godisnji-proizvodi.png";
import travel from "./../../img/Kadrovska-proizvodi.png";
import reminders from "./../../img/Podsjetnici-proizvodi.png";
import Navigation from "../Navigation";

const features = [
    {
        icon: vacation,
        titleKey: 'vacation', // JSON key for title
        descriptionKey: 'vacationProductsDesc' // JSON key for description
    },
    {
        icon: vehicles,
        titleKey: 'projects',
        descriptionKey: 'projectsProductsDesc'
    },
    {
        icon: travel,
        titleKey: 'hr',
        descriptionKey: 'travelProductsDesc'
    },
    {
        icon: reminders,
        titleKey: 'reminders',
        descriptionKey: 'noticeProductsDesc'
    }
];

export const LandingPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Navigation />
            <div className={styles.landingPage}>
                <section className={styles.heroSection}>
                    <img src={productsHero} alt="" className={styles.heroBg} />
                    <div className={styles.heroContent}>
                        <h1 className={styles.heroTitle}>{t("productsMain")}</h1>
                        <p className={styles.heroSubtitle}>
                            {t("productsDesc")}
                        </p>
                    </div>
                </section>

                <section className={styles.featureSection}>
                    <h2 className={styles.featureTitle}>{t("oaProductsTitle", "Uredski pomoćnik")}</h2>
                    <p className={styles.featureDescription}>
                        {t("oaProductsDesc")}
                    </p>
                    <div className={styles.featureGrid}>
                        {features.map((feature, index) => (
                            <FeatureCard
                                key={index}
                                icon={feature.icon}
                                title={t(feature.titleKey)}
                                description={t(feature.descriptionKey)}
                            />
                        ))}
                    </div>
                </section>

                <AssociationSection />
                <AudioSection />
                <Footer />
            </div>
        </>
    );
};
