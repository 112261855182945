import React from 'react';
import { useTranslation } from 'react-i18next';
import ServiceCard from './ServiceCard';
import styles from './../css/ServicesSection.module.css';
import ComputerIcon from '@mui/icons-material/Computer';
import DevicesIcon from '@mui/icons-material/Devices';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

const ServicesSection = () => {
  const { t } = useTranslation();

  const servicesDataColumn1 = [
    {
      IconComponent: ComputerIcon,
      title: t('service1'),
      description: t('programmingDesc'),
    },
    {
      IconComponent: DevicesIcon,
      title: t('service2'),
      description: t('digitalProductsDesc'),
    },
    {
      IconComponent: DesignServicesIcon,
      title: t('service3'),
      description: t('webDesignDesc'),
    }
  ];

  const servicesDataColumn2 = [
    {
      IconComponent: QuestionAnswerIcon,
      title: t('service4'),
      description: t('consultingDesc'),
    },
    {
      IconComponent: ViewSidebarIcon,
      title: t('service5'),
      description: t('productDesignDesc'),
    },
    {
      IconComponent: DeveloperModeIcon,
      title: t('service6'),
      description: t('MobileAppsDesc'),
    }
  ];

  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.sectionTitle}>{t('services')}</h2>
      <h3 className={styles.sectionSubtitle}>{t('productsSubtitle')}</h3>

      <div className={styles.columnsContainer}>
        <div className={styles.column}>
          {servicesDataColumn1.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className={styles.column}>
          {servicesDataColumn2.map((service, index) => (
            <ServiceCard key={index + servicesDataColumn1.length} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
