import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationHR from './locales/hr/translation.json';
import translationEN from './locales/en/translation.json';

// Define translations for different languages
const resources = {
  en: {
    translation: translationEN,  // Use the imported JSON file for English
  },
  hr: {
    translation: translationHR,  // Use the imported JSON file for Croatian
  },
};

i18n
  .use(initReactI18next) // Connects i18n to React
  .init({
    resources,
    lng: 'hr', // Default language
    fallbackLng: 'hr', // Fallback language
    keySeparator: false, // Disable nested keys, as they aren’t used
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
