import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../css/AboutSection.module.css';
import aboutImage from "./../img/About image.png";

const AboutSection = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.aboutSection}>
      <img
        loading="lazy"
        src={aboutImage}
        alt={t('aboutImageAlt', { defaultValue: 'Team working together' })}
        className={styles.aboutImage}
      />
      <div className={styles.aboutContent}>
        <h2 className={styles.aboutTitle}>{t('aboutTitle')}</h2>
        <h3 className={styles.aboutSubtitle}>
          {t('aboutSubtitle', {
            defaultValue: "NA KORAK DO APLIKACIJE IZ SNOVA"
          }).split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < line.length - 1 && <br />}
            </React.Fragment>
          ))}
        </h3>
        <p className={styles.aboutDescription}>{t('aboutDesc')}</p>
        <div className={styles.statsContainer}>
          <div className={styles.statItem}>
            <span className={styles.statNumber}>20+</span>
            <span className={styles.statLabel}>{t('projNumber')}</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statNumber}>50+</span>
            <span className={styles.statLabel}>{t('clientNumber')}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
